.App {
  /* text-align: center; */
  overflow: hidden;
}

#body {
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.examiners {
  margin: 20px;
}


.bottomChat {
  display: flex;
  margin: 10px;
  /* position: absolute;
  bottom: 10px;
  right: 20px;
  left: 20px; */
  align-items: center;
}

.examGrid {
  height: 40vh;
  width: 90vw;
}
.sendButton {
  margin-left: 10px !important;
}

.topChat {
  display: flex;
  margin: 10px;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  overflow: scroll;
  height: calc(100vh - 180px);
}

.topChatMobile {
  display: flex;
  margin: 10px;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  overflow: scroll;
  height: calc(100vh - 260px);
}

.oneMessage {
  display: inline-flex;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}

.userIcon {
  margin-right: 10px;
  color: #F4881C;
}

.botIcon {
  margin-right: 10px;
  color: #3174F5;
}

.botoneChat {
  background: #f4f8fd;;
  padding: 10px;
  border-radius: 20px;
  width: 90%;
}

.useroneChat {
  background: white;
  padding: 10px;
  border-radius: 20px;
  width: 90%;
}

.scoreMain {
  background: transparent;
  padding-left: 5px;
  display: flex;
  align-items: center;
}
.scorePercent {
  padding-left: 5px;
  padding-right: 5px;
}

.timer {
  font-family: courier !important;
}
.scoreColor {
  display: flex;
}
.scoreColorLine {
  display: flex;
  align-items: center;
}

.googleLogin {
  background: transparent;
  border: none;
}

.loginMessage {
  margin-top: 20px;
}

.privacyTos {
  position: fixed;
  display: flex;
  right: 20px;
}

.iconMessage {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.userSessions {
  margin: 10px;
}